import classNames from "classnames";
import Link from "next/link";
import { PropsWithChildren, ReactNode, useRef, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import gsap from "gsap";

const hoverBgColors = {
    primary: "#FF6347",    // Tomato color for the primary variant
    secondary: "#32CD32",  // Lime Green for the secondary variant
    custom: "#1E90FF",     // Dodger Blue for the custom variant
};

interface ButtonProps extends PropsWithChildren {
    variant?: "primary" | "secondary" | "custom";
    className?: string;
    loading?: boolean;
    icon?: ReactNode;
    link?: string;
    label: string;
    textColor?: string;
    textSize?: string;
    onClick?: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => void;
}

const Button = (props: ButtonProps) => {
    const {
        variant = "primary",
        className,
        label,
        onClick,
        link,
        loading = false,
        icon,
        textColor = "white",
        textSize = "sm"
    } = props;

    const buttonRef = useRef<HTMLDivElement | null>(null);

    /* useEffect(() => {
        const element = buttonRef.current;

        if (!element) return;

        const onMouseEnter = () => {
            gsap.to(element, {
                scale: 1.05,
                duration: 0.2,
                ease: "power1.out",
            });
        };

        const onMouseLeave = () => {
            gsap.to(element, {
                scale: 1,
                duration: 0.2,
                ease: "power1.out",
            });
        };

        element.addEventListener("mouseenter", onMouseEnter);
        element.addEventListener("mouseleave", onMouseLeave);

        return () => {
            element.removeEventListener("mouseenter", onMouseEnter);
            element.removeEventListener("mouseleave", onMouseLeave);
        };
    }, []); */

    const handleClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        const element = buttonRef.current;
        if (element) {
            gsap.fromTo(
                element,
                { scale: 1 },
                {
                    scale: 0.95,
                    duration: 0.1,
                    yoyo: true,
                    repeat: 1,
                    ease: "power1.inOut",
                }
            );
        }

        if (onClick) {
            onClick(event);
        }
    };

    const baseClasses =
        "cursor-pointer flex items-center justify-center h-full";
    const variantClasses = classNames({
        "bg-custom-button text-white border-transparent hover:bg-opacity-90":
            variant === "primary",
        "bg-custom-button-2 text-custom-button-text border-transparent hover:bg-opacity-90":
            variant === "secondary",
        "bg-custom-bg text-custom-text border-transparent hover:bg-opacity-90":
            variant === "custom",
    });

    if (loading) {
        return (
            <span
                onClick={onClick}
                className={classNames(baseClasses, variantClasses, className)}
            >
                <p className="flex items-center">
                    {label}
                    <FaSpinner className="animate-spin ml-2" />
                </p>
            </span>
        );
    }

    return (
        <Link
            href={link ?? ""}
            onClick={handleClick}
            className={classNames(baseClasses, variantClasses, className, `h-full px-3 gap-1  
                mobile:h-[7vh]
                sm:h-[7vh]
                md:h-[9vh]
                md:px-5`)}
        >
            <p className={`text-${textColor} text-[4.5vw] sm:text-[1.6vw] md:text-[1.4vw] lg:text-[1.2vw]`}>
                {label}
            </p>
            {icon && <span className="">{icon}</span>}
        </Link>
    );
};

export default Button;
